<template>
  <div class="admin-categories-listing">
    <title-bar addBtn :addUrl="getAddCategoryURL()" title="Product categories" />
    <vue-good-table
      class="custom-table-style possible-vertical-align"
      mode="remote"
      :totalRows="pages"
      :isLoading.sync="isLoading"
      :columns="columns"
      :rows="categories"
      :pagination-options="{
        enabled: true,
        perPage: perPage,
        setCurrentPage: currentPage,
      }"
      @on-page-change="onPageChange"
    >
      <template slot="table-row" slot-scope="props">
        <span class="buttons" v-if="props.column.field == 'id'">
          <div class="just-buttons">
            <edit-btn
              :path="getEditCategoryURL(props.formattedRow[props.column.field])"
            />
            <delete-btn @pressDelete="deleteCourier(props.row['@id'])" />
          </div>
        </span>

        <span v-else-if="props.column.field == 'enabled'">
          <b-badge :variant="props.row.enabled ? 'success' : 'danger'"
            >{{ props.row.enabled ? 'ENABLED' : 'DISABLED' }}
          </b-badge>
        </span>

        <span :class="props.column.field" v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <template slot="pagination-bottom" slot-scope="props">
        <table-pagination
          v-model="currentPage"
          :total="pages"
          :per-page="perPage"
          :pageChanged="props.pageChanged"
          :perPageChanged="props.perPageChanged"
        />
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import TitleBar from '@/components/TitleBar.vue'
import TablePagination from '@/components/Table/TablePagination.vue'
import EditBtn from '@/components/Buttons/EditBtn.vue'
import DeleteBtn from '@/components/Buttons/DeleteBtn.vue'
import Toast from '@/components/Toast.vue'
import ListUtils from '@/mixins/ListUtils'
import 'vue-good-table/dist/vue-good-table.css'

export default {
  components: {
    TitleBar,
    VueGoodTable,
    TablePagination,
    EditBtn,
    DeleteBtn,
  },
  mixins: [ListUtils],
  data() {
    return {
      apiUrl: this.$store.state.apiUrl,
      isLoading: false,
      perPage: 15,
      currentPage: 1,
      pages: 0,
      categories: null,

      columns: [
        {
          field: 'name',
          sortable: false,
        },
        {
          field: 'enabled',
          sortable: false,
        },
        {
          field: 'id',
          sortable: false,
          width: '100%',
        },
      ],

    }
  },
  methods: {
    loadCategories() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
        'order[enabled]': 'desc'
      }
      if (this.search && this.search.length) {
        params.search = this.search
      }

      this.$Categories.getCollection({ params }, 'category_list').then(response => {
        this.pages = response.data['hydra:totalItems']
        this.categories = response.data['hydra:member']
      })
    },
    deleteCourier(url) {
      this.$Categories
        .deleteResourceByUrl({ url })
        .then(response => {
          if (response.status === 204) {
            this.$helper.showToast(
              this.$toast,
              Toast,
              'Category deleted',
              '',
              'success',
            )
            this.loadCategories()
          }
        })
        .catch(error => {
          if (error.response) {
            this.$emit('clearAction')
            this.$helper.showToast(
              this.$toast,
              Toast,
              'Failed to delete',
              '',
              'danger',
            )
          }
        })
    },
    onPageChange() {
      this.loadCategories()
    },
    getAddCategoryURL() {
      return `/add-category`
    },
    getEditCategoryURL(id) {
      return `/categories/${id}/edit`
    },
  }
}
</script>
